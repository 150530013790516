<template>
  <div id="view-gift-cards-admin">

    <breadcrumb-base :breadcrumb="breadcrumb" title="GiftCards">
      <template v-slot:right-end>
        <vs-button class="mt-3 py-4 px-3 mr-2" color="primary"
                   @click="openGiftCardBatchSearch">{{ $t('BatchSearch') }}
        </vs-button>
        <vs-button class="mt-3 py-4 px-3" color="primary"
                   @click="openAddGiftCardBatch">{{ $t('AddGiftCardBatch') }}
        </vs-button>
      </template>
    </breadcrumb-base>

    <div class="vx-row">

      <div class="vx-col md:w-1/4">
        <vx-card>
          <div class="vx-col py-3">
            <dynamic-customers-drop-down
              :show-clear="true"
              :only-seller="true"
              :selected-item="selectedCustomer"
              :placeholder="$t('SearchCustomer')"
              @on-item-selected="onCustomerChanged($event)">
            </dynamic-customers-drop-down>
          </div>
          <div class="vx-col py-1">
          </div>
          <div class="vx-col">

            <dynamic-gift-cards-drop-down
              :show-clear="true"
              :selected-item="selectedProduct"
              :placeholder="$t('SearchProduct')"
              @on-item-selected="onProductChanged($event)">
            </dynamic-gift-cards-drop-down>
          </div>
          <div class="vx-col py-3">
            <label
              style="font-size: 12px; font-weight: bold">{{ $t('DenominationAmount') }}
              <span
                @click="selectedProductConfig = null;onProductConfigChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <vs-select
              autocomplete
              class="w-full"
              v-model="selectedProductConfig"
              @input="onProductConfigChanged"
            >
              <vs-select-item :key="index" :value="product" :text="product.value | germanNumberFormat"
                              v-for="(product,index) in selectedProduct.configurations"/>
            </vs-select>
          </div>

          <vs-button class="mt-3 w-full py-4 px-3" color="primary"
                     @click="filterData">{{$t('Filter').toUpperCase()}}
          </vs-button>
          <vs-button class="mt-3 w-full py-4 px-3" color="primary"
                     @click="clearAllFilters">{{$t('ClearAllFilters').toUpperCase()}}
          </vs-button>
        </vx-card>
      </div>
      <div class="vx-col md:w-3/4">

        <!--    Error List State-->
        <transition name="fade">
          <div class="py-6 flex w-full bg-img" v-if="errorFetching">
            <div
              class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
              <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
                   class="mx-auto mb-4 max-w-full">
              <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
            </div>
          </div>
        </transition>
        <!--    Empty List State -->
        <transition name="fade">
          <div class="py-6 flex w-full bg-img" v-if="giftCards.length === 0 && !errorFetching">
            <div
              class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--              <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                   class="mx-auto mb-4 max-w-full">-->
              <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
            </div>
          </div>
        </transition>
        <vs-table-modified class="flex-1-1 w-full" v-show="giftCards.length > 0 && !errorFetching"
                           ref="table"
                           :max-items="20"
                           :data="giftCards"
                           v-on:selected="openGiftCardDetails"
        >
          <template slot="thead">
            <vs-th >{{ $t('ProductName') }}</vs-th>
            <vs-th>{{ $t('ProductImage') }}</vs-th>
            <vs-th >{{ $t('Value') }}</vs-th>
            <vs-th >{{ $t('Sold') }}</vs-th>
            <vs-th >{{ $t('UnSold') }}</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.product_name }}</p>
              </vs-td>
              <vs-td>
                <img :src="tr.product_image" :alt="tr.product_name" class="product-image"></img>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">{{
                    tr.product_configuration_value | germanNumberFormat
                  }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name">{{ tr.gift_cards_sold_count }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name">{{ tr.gift_cards_unsold_count }}</p>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
          <template slot="footer">
          </template>
          <template slot="footer-left">
            <div class="flex">
              <vs-new-pagination :total="vTotalPages" v-model="currentPage" v-on:change="listPageChanged">
              </vs-new-pagination>
            </div>
          </template>
        </vs-table-modified>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown'
import DynamicGiftCardsDropDown from '../products/DynamicGiftCardsDropDown'


export default {
  name: 'ViewGiftCards',
  components: { DynamicCustomersDropDown, DynamicGiftCardsDropDown },
  data() {
    return {
      errorFetching: false,
      selectedProductConfig: {},
      selectedProduct: {},
      selectedCustomer: {},
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'GiftCards', i18n: 'GiftCards', active: true },
      ],
      currentPage: 1,
    }
  },
  computed: {
    giftCards() {
      return this.$store.state.adminGiftCards.giftCards
    },
    vPage() {
      return this.$store.state.adminGiftCards.vPage
    },
    vTotalPages() {
      return this.$store.state.adminGiftCards.vTotalPages
    },
    vLimit() {
      return this.$store.state.adminGiftCards.itemCount
    },
  },
  methods: {
    openGiftCardDetails(d) {
      this.$router.push({ name: 'admin-giftcard', params: { card: btoa(JSON.stringify(d)) } })
    },
    openGiftCardBatchSearch() {
      this.$router.push({ name: 'admin-giftcards-search' })
    },
    openAddGiftCardBatch() {
      this.$router.push({ name: 'admin-giftcards-add' })
    },
    onProductChanged(product) {
      this.selectedProduct = product
    },
    onProductConfigChanged() {

    },
    onCustomerChanged(customer) {
      this.selectedCustomer = customer
    },
    clearAllFilters() {
      this.selectedCustomer = {}
      this.selectedProduct = {}
      this.selectedProductConfig = ''
      this.fetchGiftCards()
    },
    buildPayload() {
      const payload = {}
      if (Object.keys(this.selectedProduct).length > 0) {
        payload.product_id = this.selectedProduct.product_id
        if (Object.keys(this.selectedProductConfig).length > 0) {
          payload.product_configuration_id = this.selectedProductConfig.product_configuration_id
        }
      }
      if (Object.keys(this.selectedCustomer).length > 0) {
        payload.customer_id = this.selectedCustomer.customer_id
      }
      return payload
    },
    filterData() {
      this.fetchGiftCards(this.buildPayload())
    },
    listPageChanged(item) {
      this.fetchGiftCards(this.buildPayload(), this.currentPage - 1)
    },
    fetchGiftCards(payload = null, page = '0') {
      this.$vs.loading()
      return this.$store.dispatch('adminGiftCards/fetchGiftCards', { payload, page })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          let msg = ''
          try {
            msg = error.response.data.message || error.message
          } catch (err) {
            msg = err.message
          }
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    loadProducts(isMore = false, filter = null, search = null) {
      const filters = {
        product_type: 'gift card',
        total_items: 100,
      }
      this.$vs.loading()
      return this.$store.dispatch('products/fetchProducts', { isMore, filter, search })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    // this.fetchGiftCards()
  },
}
</script>

<style scoped lang="scss">
#view-gift-cards-admin {
  .product-image {
    /*max-width: 200px;*/
    max-height: 40px;
    object-fit: cover;
  }
}
</style>
